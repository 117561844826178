<template>
  <div class="wrapper">
    <div class="title">
      <span>加入我们</span>
      <img src="~@/assets/about/join/about_join_title.png" alt="">
    </div>
    <div class="container">
      <div class="join-desc-list">
        <ul class="join-list-box">
          <li v-for="(item, index) in list" :key="index">
            <div class="desc-item-box" :class="index %2 === 0 ? 'left' : 'right'">
              <div
                class="desc-item"
                v-for="(desc, idx) in item.desc"
                :key="idx"
              >
                {{ desc }}
              </div>
            </div>
            <img :class="index %2 === 0 ? 'right' : 'left'" :src="item.img" alt="img">
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Join',
  data () {
    return {
      list: [
        {
          desc: [
            '这里是一支极致的团队',
            '到处充满专注的力量',
            '这里有一支不断挑战的力量',
            '每个人都勇攀高峰',
            '为了胜利勇敢前进',
          ],
          img: require('@/assets/about/join/about_join_1.png'),
        },
        {
          desc: [
            '我们相信相信的力量',
            '专业、踏实、创新',
            '共同的追求，共同的理念',
            '共同的西图盟品质',
            '为离散制造业带去适合的',
            '且卓有成效的数字化成果',
          ],
          img: require('@/assets/about/join/about_join_2.png'),
        },
        {
          desc: [
            '来吧',
            '让我们一起成为工业逐浪者',
            '为中国制造添薪加火',
            '与你同行',
            '不忘初心',
            '不负时代',
          ],
          img: require('@/assets/about/join/about_join_3.png'),
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/style/global';

.wrapper {
  width: 100%;
  position: relative;
}

.container {
  width: 100%;
  position: relative;
  background-image: url('~@/assets/about/join/about_join_page_bg.png');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #064ACB;
}

.join-desc-list {
  .g_center();
  padding: 40px 0;
}

.title {
  width: 100%;
  height: 525px;
  position: relative;

  & > span {
    width: 384px;
    height: 134px;
    display: inline-block;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 96px;
    line-height: 134px;
    color: #FFFFFF;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 35%;
    z-index: 1;
  }

  & > img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.join-list-box {
  width: 100%;
  li {
    height: 350px;
    padding: 80px;
    border-bottom: 1px solid #FFFFFF;
    overflow: hidden;
    position: relative;
    &:last-child {
      border: none;
    }
    & > img {
      width: 600px;
      height: 350px;
    }
    .desc-item-box {
      width: 280px;
      height: 350px;
      margin-top: 100px;
      .desc-item {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        display: flex;
        align-items: center;
        text-align: right;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 12px;
        position: relative;
      }
      & :first-child {
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
      }
    }
    .left {
      float: left;
    }
    .right {
      float: right;
    }
    .desc-item-box.left {
      text-align: left;
      .desc-item {
        text-align: left;
        &:first-child{
          &::before {
            position: absolute;
            width: 36px;
            height: 2px;
            display: block;
            background: #FFFFFF;
            transform: translateY(16px);
            left: 0;
            content: '';
          }
        }
      }
    }
    .desc-item-box.right {
      text-align: right;
      .desc-item {
        display: inline-block;
        text-align: right;
        &:first-child {
          &::after {
            position: absolute;
            right: 0;
            width: 36px;
            height: 2px;
            display: block;
            background: #FFFFFF;
            transform: translateY(12px);
            content: '';
          }
        }
      }
    }
  }
}
</style>
